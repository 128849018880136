import { Component, HostListener, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { Router } from '@angular/router';
import { UserRepository } from '@data/user/user.store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { appConfig } from '../app.config';

@UntilDestroy()
@Component({
  selector: 'recrewt-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = appConfig.app.name;

  isIdle = false;

  isReloadWithId = false;

  loggedIn = false;

  lastPing: Date | null = null;

  constructor(
    private translate: TranslateService,
    private idle: Idle,
    private keepalive: Keepalive,
    private router: Router,
    private userRepo: UserRepository,
  ) {
    window.document.title = appConfig.app.title;
    translate.addLangs(['de', 'en']);
    translate.setDefaultLang('de');

    const lang = window.localStorage.getItem('lang');
    if (lang && lang !== 'undefined') {
      translate.use(lang);
    }
  }

  ngOnInit() {
    if (window.localStorage.getItem('loggedInBeforeUnload')) {
      this.isReloadWithId = true;
      window.localStorage.removeItem('loggedInBeforeUnload');
    }

    this.idle.setIdle(5 * 60);
    this.idle.setTimeout(25 * 60);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idle.onIdleEnd.subscribe(() => {
      console.log('End Idle');
      this.resetOnMove();
    });

    this.idle.onTimeout.subscribe(() => {
      console.log('Timeout');
      this.logout();
    });

    this.idle.onIdleStart.subscribe(() => {
      if (!this.loggedIn) {
        this.idle.stop();
        return;
      }
      this.isIdle = true;
    });

    this.keepalive.interval(15);
    this.keepalive.onPing.subscribe(() => (this.lastPing = new Date()));

    this.userRepo.hasUser$.pipe(untilDestroyed(this)).subscribe((hasUser) => {
      this.loggedIn = hasUser;
      if (hasUser) {
        this.invalidateCacheOnTimeout();
      }
    });

    this.reset();
  }

  logout() {
    this.userRepo.logoutUser();
    this.router.navigate(['/']);
    this.isIdle = false;
    this.isReloadWithId = false;
  }

  reset() {
    this.idle.watch();
    this.isIdle = false;
  }

  @HostListener('window:beforeunload')
  onUnload() {
    window.localStorage.setItem('lastLogin', String(Date.now()));
    if (this.loggedIn) {
      window.localStorage.setItem('loggedInBeforeUnload', 'true');
    }
    window.localStorage.setItem('lang', this.translate.currentLang);
  }

  private invalidateCacheOnTimeout() {
    const lastLogin = window.localStorage.getItem('lastLogin');
    if (!lastLogin) {
      return;
    }

    const diff = Date.now() - parseInt(lastLogin);
    if (diff <= 1000 * 60 * 30 /* 30 minutes */) {
      return;
    }
    this.logout();
  }

  private resetOnMove() {
    if (this.isIdle) {
      return;
    }

    if (this.loggedIn) {
      console.log('reset');
      this.reset();
    } else {
      console.log('stop');
      this.idle.stop();
    }
  }
}
