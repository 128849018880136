<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="24px">
  <mat-icon
    [style]="{ borderColor: color ?? '#e53a7d', color: color ?? '#e53a7d' }"
    class="circle-icon"
  >
    {{ icon }}
  </mat-icon>
  <div>
    <p class="no-pad mat-body-2 on-surface-medium">{{ title | translate }}</p>
    <p *ngIf="desc" class="mat-body-2 text-weight-bold no-pad">
      {{ desc | translate : _param }}
    </p>
  </div>
</div>
