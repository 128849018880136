import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: '[color] recrewt-stage-item',
  templateUrl: './stage-item.component.html',
  styleUrls: ['./stage-item.component.scss'],
})
export class StageItemComponent implements OnInit {
  @Input() icon = 'add';

  @Input() color?: string;

  @Input() title = '';

  @Input() desc: string | null = '';

  @Input() param = '';

  _param = { str: '' };

  ngOnInit(): void {
    this._param = { str: this.param };
  }
}
