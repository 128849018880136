<div class="relative">
  <recrewt-language-select class="bottom-left-higher"></recrewt-language-select>
  <img
    [class]="position"
    [ngStyle.gt-xs]="{ maxWidth: '80px', height: 'auto' }"
    [ngStyle.xs]="{ maxWidth: '40px', height: 'auto' }"
    [src]="watermarkSrc"
  />
  <router-outlet></router-outlet>
  <p class="bottom-right on-surface-medium">Powered by RECREWT</p>
</div>
