<div *ngIf="icon" class="m-b-3" fxLayoutAlign="center end">
  <img alt="Icon" height="64" src="assets/images/albert-cry.svg" />
</div>
<h2 class="m-b-1 text-align-center">{{ headline | translate }}</h2>
<p class="on-surface-medium empty-state-text m-b-3 text-align-center">
  {{ (text | translate) + dynamicText }}
</p>
<button
  (click)="buttonClicked.emit()"
  *ngIf="!!buttonText?.length"
  color="accent"
  mat-raised-button
>
  <mat-icon *ngIf="!!buttonIcon">{{ buttonIcon }}</mat-icon>
  {{ buttonText | translate }}
</button>
